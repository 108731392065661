export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const dateOptions: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };
  const dateStr = new Intl.DateTimeFormat("en-US", dateOptions).format(date);
  const timeStr = new Intl.DateTimeFormat("en-US", timeOptions).format(date);
  return `${dateStr} at ${timeStr}`;
};
