import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { getCanonicalUrl } from "../utils/urlUtils";

interface SEOProps {
  title: string;
  description?: string;
  canonicalPath?: string;
  isAuthRequired?: boolean;
}

export default function SEO({
  title,
  description,
  canonicalPath,
  isAuthRequired,
}: SEOProps) {
  const location = useLocation();
  const canonical = getCanonicalUrl(canonicalPath || location.pathname);

  return (
    <Helmet>
      <title>{title}</title>
      {description && <meta name="description" content={description} />}
      <link rel="canonical" href={canonical} />
      {isAuthRequired && <meta name="robots" content="noindex" />}
    </Helmet>
  );
}
