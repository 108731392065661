export const normalizeUrl = (path: string): string => {
  // Remove trailing slashes
  let normalized = path.replace(/\/+$/, "");

  // Remove query parameters
  normalized = normalized.split("?")[0];

  // Ensure starts with slash
  if (!normalized.startsWith("/")) {
    normalized = "/" + normalized;
  }

  return normalized;
};

export const getCanonicalUrl = (path: string): string => {
  return `https://echonotes.ai${normalizeUrl(path)}`;
};
