import React, { useEffect } from "react";
import ReactGA from "react-ga4";

import PublicHeader from "../components/PublicHeader";
import Footer from "../components/Footer";
import {
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";
import SEO from "../components/SEO";

export default function AboutPage() {
  // Google Analytics
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/about",
      title: "About Page",
    });
  }, []);

  return (
    <>
      <SEO
        title="Why we built Echo - About"
        description="We're building a better interface to your thoughts. Echo was cofounded by Reuben Abraham and Thomas Officer."
        canonicalPath="/about"
      />
      <div>
        <PublicHeader />
        <div className="bg-white px-6 py-60 lg:px-8">
          <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
            <p className="text-base font-semibold leading-7 text-indigo-600">
              About Echo
            </p>
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl font-lustria font-bold sm:leading-[1.5]">
              Building a better interface to your thoughts
            </h1>
            <p className="mt-6 text-xl leading-8">
              We make your voice and text notes more useful by organizing and
              synthesizing them for you. We allow you to draft content based on
              your notes and receive AI-driven recommendations to enhance and
              expand your ideas effortlessly.
            </p>
            <div className="mt-10 max-w-2xl">
              <p>
                We shouldn’t be hunched over our phones to capture our thoughts.
                It’s understandable why we do it. Note-taking is an excellent
                way to keep track of the daily logistics of our lives, our
                rambling thoughts, and our memories. Although it is a pain to
                organize all these notes and to actually make use of them.
              </p>
              <p className="mt-6">
                What if you could just talk to a notebook that has perfect
                memory? What if that notebook could talk back and could help you
                organize your notes and transform them into whatever you need
                them to be? That’s Echo.
              </p>
              <ul role="list" className="mt-8 max-w-xl space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  <CheckCircleIcon
                    aria-hidden="true"
                    className="mt-1 h-5 w-5 flex-none text-indigo-600"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      Voice-to-text.
                    </strong>{" "}
                    Use your voice to quickly capture spontaneous thoughts, even
                    if you’re on-the-go and away from your desk.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <CheckCircleIcon
                    aria-hidden="true"
                    className="mt-1 h-5 w-5 flex-none text-indigo-600"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      Secure memory.
                    </strong>{" "}
                    Echo remembers every detail of your recorded insights,
                    providing you with an organized, private, and secure archive
                    of your thoughts.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <CheckCircleIcon
                    aria-hidden="true"
                    className="mt-1 h-5 w-5 flex-none text-indigo-600"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      Ask your notebook anything.
                    </strong>{" "}
                    Ask for guidance, recommendations, or inspiration. Echo's
                    intelligence is built using the GPT-4o large language model.
                  </span>
                </li>
              </ul>
              <p className="mt-6">
                Echo is a better interface to your thoughts. It reduces stress
                and screen time. It also helps us recall details and explore
                ideas. Particularly those ideas that are too big to tackle
                directly, that need to be chiseled away through rambling
                thoughts spoken out loud while going for a walk.
              </p>
            </div>
            <div className="mt-16 max-w-2xl">
              <h2 className="text-2xl font-bold tracking-tight text-gray-900 font-lustria font-bold sm:leading-[1.5]">
                Meet the team
              </h2>
              <p className="mt-6">
                Echo was created by{" "}
                <a
                  href="https://www.linkedin.com/in/reuben-abraham-9a348298/"
                  className="text-indigo-600 hover:text-indigo-400"
                >
                  Reuben Abraham
                </a>{" "}
                (CEO) and{" "}
                <a
                  href="https://www.thomasofficer.com/"
                  className="text-indigo-600 hover:text-indigo-400"
                >
                  Thomas Officer
                </a>
                . Reuben and Thomas met after each had quit their jobs to
                explore the potential of large language models for better
                memory, learning, and general thinking.
              </p>
              <p className="mt-8">
                Reuben grew up in Dubai, where he was an avid cricket player
                before moving to the US to pursue his passion for technology.
                Now living in San Francisco with his wife, he came up with the
                idea for Echo when on a long walk along Crissy Field and needed
                a quick way to get down ideas on his last app for small
                businesses. Reuben likes to take a variety of notes, from
                favorite restaurants to new product ideas. Previously, Reuben
                worked at Pave and NerdWallet, and graduated from the University
                of Pennsylvania.
              </p>
              <p className="mt-8">
                Thomas was born in France, went to law school in Scotland, and
                currently lives in Lebanon, NH. He loves sci-fi and is still
                waiting to meet someone who loves the Terra Ignota novel series
                as much as he does. Previously, Thomas was a cofounder at
                Afterpattern (acquired 2021) and a design fellow at Blue Ridge
                Labs @ Robin Hood.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
