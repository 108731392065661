import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { normalizeUrl } from "../utils/urlUtils";

export default function NormalizedRoute({
  children,
}: {
  children: React.ReactNode;
}) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = location.pathname;
    const normalizedPath = normalizeUrl(location.pathname);

    // Redirect if URL isn't in canonical form
    if (currentPath !== normalizedPath) {
      navigate(normalizedPath, { replace: true });
    }
  }, [location, navigate]);

  return <>{children}</>;
}
