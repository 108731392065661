import React, { useEffect } from "react";
import ReactGA from "react-ga4";

import PublicHeader from "../components/PublicHeader";
import Footer from "../components/Footer";
import SEO from "../components/SEO";

const faqs = [
  {
    id: 1,
    question: "Are my entries private and secure?",
    answer:
      "Yes, your privacy and security are our top priorities. Your notes are encrypted, we do not look at your data, nor is your data used to train any AI. We follow industry best practices for data protection and regularly update our security measures to safeguard your personal information.",
  },
  {
    id: 2,
    question: "Is Echo free to use?",
    answer:
      "Yes, the Echo beta is completely free to use. We believe that everyone should have access to tools that help them develop their ideas. Eventually, we're planning to introduce premium features to enhance your idea development experience even further.",
  },
  {
    id: 3,
    question: "How does Echo use AI?",
    answer:
      "Echo leverages the GPT-4o large language model for transcription, recall, and insight generation. When you record your voice, the AI transcribes your entries accurately. You can ask the AI questions about your past entries, and it will search through them to provide meaningful and insightful answers, making for a truly novel idea development experience.",
  },
];

const HeroTestimonials = [
  {
    body: "It accumulates information over time and connects them, almost like creating a narrative for each topic.",
    author: "Anonymous",
  },
  {
    body: "It's my favorite app actually. It's my favorite work app now… It's been very impactful for me, as much as ChatGPT.",
    author: "Anonymous",
  },
  {
    body: "Very easy to record, organize, and summarize your ideas all in one place",
    author: "Anonymous",
  },
];

const testimonials = [
  {
    body: "I came here to let you know how impressed I am with this app. I use other voice transcription apps that I like a lot. This one does something I've seen on other platforms, but the way it does it is what I thought was super impressive — the way it oragnizes topics. I found it really interesting how it accumulates infomration over time as you record things and connects them, almost like creating a narrative for each topic. I thought that was incredibly interesting, and I want to highlight how well this was done.",
    author: {
      name: "Anonymous",
      handle: "@vitorioap",
      imageUrl: "/logo-reddit-avatar.png",
      link: "https://www.reddit.com/r/ProductivityApps/comments/1g060jy/comment/lrgs4r8/?utm_source=share&utm_medium=web3x&utm_name=web3xcss&utm_term=1&utm_content=share_button",
    },
  },
  {
    body: "Amazing! Very easy to record a note... and for organizing and summarizing your ideas all in one place. Highly recommend!",
    author: {
      name: "Anonymous",
      handle: "@THEcodyMANz",
      imageUrl: "/logo-a.png",
      link: null,
    },
  },
  {
    body: "I'm not someone who typically uses voice notes, but I gave this a try and honestly have been really suprised at its usefulness and how it has helped me, as a user, adopt to the benefits of the app. The basic organizational components (shopping list on the go, reminders, etc) are smooth and obvious, but the ability to take unstructured thoughts and inspiration and have them organized and on-hand (without interrupting the creative process to type, personally organize, etc) is huge. It's allowed me to run with the ideas much more easily and encouraged me to give thought to them in the short time I've used [Echo].",
    author: {
      name: "Anonymous",
      handle: "@IDetonateBrews",
      imageUrl: "/logo-reddit-avatar.png",
      link: "https://www.reddit.com/r/ProductivityApps/comments/1g060jy/comment/lrhbbbz/?utm_source=share&utm_medium=web3x&utm_name=web3xcss&utm_term=1&utm_content=share_button",
    },
  },
  {
    body: "Hoping to replace Apple Notes with Echo across my ecosystem.",
    author: {
      name: "Mikhil Innani",
      handle: "@mikhilinnani",
      imageUrl: "/logo-m.png",
      link: null,
    },
  },
  {
    body: "There are usually hundreds of things racing through my head at any given moment. I've started voice journaling using Echo and it has given me a great outlet to turn rambles into organized and clear structure to help convey my thoughts. Highly recommend for any busy professional or founder!",
    author: {
      name: "Rohan Varma",
      handle: "@rohan_varma1",
      imageUrl: "/logo-product-hunt-p.png",
      link: "https://www.producthunt.com/posts/echo-76b8e6a3-3f29-4842-9927-6d0f0b08f0fa?comment=3991156",
    },
  },
  {
    body: "Great for capturing thoughts throughout the day. Reuben's team is also incredible at listening to users and quickly launching new features.",
    author: {
      name: "Conor Brennan-Burke",
      handle: "@conorbrennanburke",
      imageUrl: "/logo-linkedin-circle.png",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:7231718424136007680?commentUrn=urn%3Ali%3Acomment%3A%28activity%3A7231718424136007680%2C7232058329403965441%29&dashCommentUrn=urn%3Ali%3Afsd_comment%3A%287232058329403965441%2Curn%3Ali%3Aactivity%3A7231718424136007680%29",
    },
  },
  {
    body: "I don't want to be sending people ChatGPT written emails. None of us want to receive a message that's 100% written by AI, it's almost disrespectful. But Echo? I feel so good about this. Yes, it's AI, but it basically just takes what you said and just organizes your notes from your own mouth.I don't feel embarrassed to use Echo.",
    author: {
      name: "Anonymous",
      handle: "",
      imageUrl: "/logo-a.png",
      link: null,
    },
  },
];

export default function HomePage() {
  // Google Analytics
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/",
      title: "Home Page",
    });
  }, []);

  return (
    <>
      <SEO
        title="Echo | AI voice notes for clear thinking"
        description="Transform your voice and text notes into organized insights. Echo helps you capture, organize, and develop your thoughts over time."
        canonicalPath="/"
      />
      <div>
        <PublicHeader />
        {/* Hero */}
        <div className="relative isolate overflow-hidden lg:overflow-visible px-6 lg:px-8">
          <div className="mx-auto max-w-7xl">
            <div className="mx-auto max-w-3xl py-36">
              <img
                src="/logo-app-store-rating.png"
                alt="Download on the App Store"
                className="sm:w-auto max-w-32 inline"
              />
              <h1 className="text-4xl pt-8 sm:leading-[1.3] tracking-tight text-gray-900 sm:text-6xl font-lustria font-bold">
                Use AI + voice notes to develop thoughts over time
              </h1>
              <p className="mt-3 sm:text-xl text-l font-light max-w-xl leading-7 sm:leading-9 text-custom-gray text-opacity-65">
                Echo is a voice and text note-taking app that uses AI to
                organize and refine your thoughts, allowing you to think deeper.
              </p>
              <div className="mt-10 flex items-center gap-x-6">
                <a
                  href="https://apps.apple.com/us/app/echo-ai-notes/id6503351019"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    ReactGA.event({
                      category: "Download",
                      action: "App Store Click",
                      label: "Hero Section",
                    })
                  }
                >
                  <img
                    src="/app-store-button-black.png"
                    alt="Download on the App Store"
                    className="w-32 inline mx-auto sm:mx-0"
                  />
                </a>
              </div>
            </div>
            <div className="mx-auto max-w-6xl flex flex-col sm:flex-row justify-between">
              <div className="flex-shrink-0 flex-grow w-full sm:w-[50%]">
                <svg
                  role="img"
                  viewBox="0 0 366 729"
                  className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl"
                >
                  <title>App screenshot</title>
                  <defs>
                    <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                      <rect rx={36} width={316} height={684} />
                    </clipPath>
                  </defs>
                  <path
                    d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
                    fill="#4B5563"
                  />
                  <path
                    d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
                    fill="#343E4E"
                  />
                  <foreignObject
                    width={316}
                    height={684}
                    clipPath="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
                    transform="translate(24 24)"
                  >
                    <img alt="" src="iphone-mock-topics-home.png" />
                  </foreignObject>
                </svg>
              </div>
              <div className="flex-shrink-0 flex-grow w-full sm:w-[50%]">
                <div className="pt-8 sm:inline-block sm:w-full sm:px-4">
                  <div className="pt-8 sm:inline-block sm:w-full sm:px-4">
                    {/* Map three selected testimonials here */}
                    {HeroTestimonials.map((testimonial, index) => (
                      <div
                        key={index}
                        className="rounded-2xl mb-8 bg-white p-6 shadow-lg ring-1 ring-gray-900/5"
                      >
                        <blockquote className="text-gray-900 font-lustria">
                          <p className="text-lg leading-8">{`“${testimonial.body}”`}</p>
                        </blockquote>
                        <figcaption className="mt-2 font-semibold text-gray-700">
                          {testimonial.author}
                        </figcaption>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Trusted By section */}
        <div className="bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <h2 className="text-center text-lg text-custom-gray text-opacity-65 leading-8">
              Trusted by thoughtful people from
            </h2>
            <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
              <img
                alt="UPenn"
                src="/logo-upenn.png"
                width={158}
                height={48}
                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              />
              <img
                alt="Google"
                src="/logo-google.png"
                width={158}
                height={48}
                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              />
              <img
                alt="Harvard"
                src="/logo-harvard.png"
                width={158}
                height={48}
                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              />
              <img
                alt="Meta"
                src="/logo-meta.png"
                width={158}
                height={48}
                className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
              />
              <img
                alt="Uber"
                src="/logo-uber.png"
                width={158}
                height={48}
                className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
              />
            </div>
          </div>
        </div>

        {/* Demo video */}
        <div className="relative w-full max-w-4xl mx-auto my-20">
          <div
            style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}
          >
            <iframe
              src="https://www.loom.com/embed/c759c9c0235a4b42be699bb54d521d6f?sid=eacecce4-c217-4b47-88dc-0afdcb42b113"
              allowFullScreen
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              title="Demo Video"
            ></iframe>
          </div>
        </div>

        {/* About Echo */}
        <div className="mx-auto max-w-2xl px-6 py-20 sm:py-20 lg:px-8 lg:py-20">
          <p className="mt-6 text-xl leading-9 font-lustria">
            Use voice & text notes to capture thoughts and Echo will
            automatically clean and organize them for you. When you're ready,
            Echo helps you leverage this clear and organized thinking for:
          </p>
          <ol className="text-xl leading-9 font-lustria mt-6">
            <li>→ Decision making;</li>
            <li>→ Content creation;</li>
            <li>→ Personal & professional goals;</li>
            <li>→ And more...</li>
          </ol>
        </div>

        {/* Features */}
        <div className="relative">
          <div className="bg-[#7A9EBB] h-1/6 absolute w-full bottom-0 z-0"></div>
          <div className="mx-auto max-w-6xl grid grid-cols-1 lg:grid-cols-3 gap-20 px-6 py-12 lg:px-8 lg:py-30 z-10">
            {/* Column 1 */}
            <div className="flex flex-col items-center text-center lg:items-start lg:text-left z-10">
              <svg
                viewBox="0 0 366 729"
                role="img"
                className="w-full max-w-full drop-shadow-xl mb-6"
              >
                <title>App screenshot</title>
                <defs>
                  <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                    <rect width={316} height={684} rx={36} />
                  </clipPath>
                </defs>
                <path
                  fill="#4B5563"
                  d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
                />
                <path
                  fill="#343E4E"
                  d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
                />
                <foreignObject
                  width={316}
                  height={684}
                  transform="translate(24 24)"
                  clipPath="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
                >
                  <img src="/iphone-mock-recording.png" alt="" />
                </foreignObject>
              </svg>
            </div>

            {/* Column 2 */}
            <div className="flex flex-col items-center text-center lg:items-start lg:text-left z-10">
              <svg
                viewBox="0 0 366 729"
                role="img"
                className="w-full max-w-full drop-shadow-xl mb-6"
              >
                <title>App screenshot</title>
                <defs>
                  <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                    <rect width={316} height={684} rx={36} />
                  </clipPath>
                </defs>
                <path
                  fill="#4B5563"
                  d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
                />
                <path
                  fill="#343E4E"
                  d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
                />
                <foreignObject
                  width={316}
                  height={684}
                  transform="translate(24 24)"
                  clipPath="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
                >
                  <img src="/iphone-mock-note-detail-page.png" alt="" />
                </foreignObject>
              </svg>
            </div>

            {/* Column 3 */}
            <div className="flex flex-col items-center text-center lg:items-start lg:text-left z-10">
              <svg
                viewBox="0 0 366 729"
                role="img"
                className="w-full max-w-full drop-shadow-xl mb-6"
              >
                <title>App screenshot</title>
                <defs>
                  <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                    <rect width={316} height={684} rx={36} />
                  </clipPath>
                </defs>
                <path
                  fill="#4B5563"
                  d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
                />
                <path
                  fill="#343E4E"
                  d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
                />
                <foreignObject
                  width={316}
                  height={684}
                  transform="translate(24 24)"
                  clipPath="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
                >
                  <img src="/iphone-mock-query-prompts.png" alt="" />
                </foreignObject>
              </svg>
            </div>
          </div>
        </div>

        {/* Customer testimonials */}
        <div className="bg-[#476C6F] py-24 sm:py-32" id="testimonials">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-xl text-center">
              <img
                alt="Stars"
                src="/logo-5-stars.png"
                width={158}
                height={48}
                className="col-span-2 max-h-12 w-lg mx-auto mb-6 object-contain lg:col-span-1"
              />
              <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl font-lustria font-bold">
                Don't just take our word for it
              </p>
            </div>
            <div className="mx-auto mt-16 flow-root max-w-2xl sm:mt-20 lg:mx-0 lg:max-w-none">
              <div className="-mt-8 sm:-mx-4 sm:columns-2 sm:text-[0] lg:columns-3">
                {testimonials.map((testimonial) => (
                  <div
                    key={testimonial.author.handle}
                    className="pt-8 sm:inline-block sm:w-full sm:px-4"
                  >
                    <figure className="rounded-2xl bg-gray-50 p-8 text-sm leading-6">
                      <blockquote className="text-gray-900">
                        <p>{`“${testimonial.body}”`}</p>
                      </blockquote>
                      <figcaption className="mt-6 flex items-center gap-x-4">
                        <img
                          alt=""
                          src={testimonial.author.imageUrl}
                          className="h-10 w-10 rounded-full bg-gray-50"
                        />
                        <div>
                          <div className="font-semibold text-gray-900">
                            {testimonial.author.name}
                          </div>
                          {/* Conditionally render the handle as a link or text */}
                          {testimonial.author.link ? (
                            <a
                              href={testimonial.author.link}
                              className="text-blue-600 hover:underline"
                              target="_blank"
                              rel="noopener noreferrer"
                            >{`${testimonial.author.handle}`}</a>
                          ) : (
                            <div className="text-gray-600">{`${testimonial.author.handle}`}</div>
                          )}
                        </div>
                      </figcaption>
                    </figure>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Call to action */}
        <div className="bg-white">
          <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl font-lustria font-bold sm:leading-[1.5]">
                Ready to get started?
                <br />
                Download Echo for free.
              </h2>
              <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
                Start building your intelligent notebook and discover the
                benefits of organized thoughts and intelligent recall.
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <a
                  href="https://apps.apple.com/us/app/echo-ai-notes/id6503351019"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    ReactGA.event({
                      category: "Download",
                      action: "App Store Click",
                      label: "Hero Section",
                    })
                  }
                >
                  <img
                    src="/app-store-button-black.png"
                    alt="Download on the App Store"
                    className="w-32 inline mx-auto sm:mx-0"
                  />
                </a>
                <a
                  href="/about"
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  About us <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* FAQ section */}
        <div className="mx-auto max-w-5xl divide-y divide-gray-900/10 px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
            Frequently asked questions
          </h2>
          <dl className="mt-10 space-y-8 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <div
                key={faq.id}
                className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8"
              >
                <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">
                  {faq.question}
                </dt>
                <dd className="mt-4 lg:col-span-7 lg:mt-0">
                  <p className="text-base leading-7 text-gray-600">
                    {faq.answer}
                  </p>
                </dd>
              </div>
            ))}
          </dl>
        </div>

        {/* AI Tools Badge */}
        <div className="flex justify-center my-10">
          <a
            href="https://aitools.inc/tools/echo-ai?utm_source=embed-badge-echo-ai&utm_medium=embed&utm_campaign=embed-badge-featured"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://aitools.inc/tools/echo-ai/embeds/v1/featured-badge.svg?theme=neutral"
              alt="Echo | AI Tools"
              width="175"
              height="54"
            />
          </a>
        </div>

        <Footer />
      </div>
    </>
  );
}
