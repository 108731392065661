import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import SEO from "../components/SEO";

import PublicHeader from "../components/PublicHeader";
import Footer from "../components/Footer";

const faqs = [
  {
    id: 1,
    question: "What is Echo?",
    answer:
      "Echo is an iPhone app that helps you easily capture and organize your thoughts. Whether you speak out loud or type, Echo uses AI to turn your ideas and reminders into an organized notebook. Dive into your notes, ask questions, and uncover deeper insights into your musings.",
  },
  {
    id: 2,
    question: "How do I start using Echo?",
    answer:
      "Download the Echo app from the App Store, create a free account, and start capturing your thoughts by speaking out loud or typing. Echo will automatically organize your notes for you. Ask Echo questions about your notes to get instant answers and insights.",
  },
  {
    id: 3,
    question: "Is Echo free to use?",
    answer:
      "Yes, Echo is completely free to use. We believe that everyone should have access to tools that help them reflect and grow. Eventually, we're planning to introduce premium features to enhance your note-taking experience even further.",
  },
  {
    id: 4,
    question: "Who owns my notes?",
    answer:
      "You do! Echo is committed to ensuring that your thoughts and data are your property. Your notes are securely stored within the app, and we prioritize your privacy and security.",
  },
  {
    id: 5,
    question: "How does Echo keep my notes private and secure?",
    answer:
      "Your privacy and security are our top priorities. Your entries are stored securely, and they are not publicly available. We follow industry best practices for data protection and regularly update our security measures to safeguard your personal information. Technically, we have the ability to access your entries, but practically, everything is locked down tight. We adhere to strict access protocols and would only access your data with your explicit permission as part of troubleshooting.",
  },
  {
    id: 6,
    question: "How does Echo use AI?",
    answer:
      "Echo uses AI for transcription, recall, and insight generation. When you record your voice, the AI transcribes your entries accurately. You can ask the AI questions about your past reflections, and it will search through your entries to provide meaningful and insightful answers, making your idea development experience more interactive and engaging.",
  },
  {
    id: 7,
    question: "What kinds of questions can I ask Echo?",
    answer:
      "You can ask Echo any question about your notes, such as recalling specific details, summarizing multiple notes, or uncovering patterns in your thoughts. Echo’s AI will provide accurate and synthesized answers.",
  },
  {
    id: 8,
    question: "What if I don’t know what to take notes about?",
    answer:
      "Echo provides detailed prompts to get you thinking and give meaningful answers, yielding powerful insights from Echo's AI.",
  },
  {
    id: 9,
    question: "Can I export my notes from Echo?",
    answer:
      "Yes, there's nothing locking you in, so give Echo a try! You can email support@echonotes.ai for a zip file of your notes. You will be able to soon do this automatically from inside the app.",
  },
];

export default function HelpPage() {
  // Google Analytics
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/help",
      title: "Help Page",
    });
  }, []);

  return (
    <>
      <SEO
        title="Help Center - Echo"
        description="Contact us at support@echonotes.ai"
        canonicalPath="/help"
      />
      <div>
        <PublicHeader />
        {/* Hero */}
        <div className="bg-white px-6 py-24 sm:py-32 lg:px-8">
          <div className="mx-auto max-w-2xl text-center py-24 sm:py-32 lg:px-8">
            <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl font-lustria font-bold sm:leading-[1.5]">
              Help Center
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Contact us at support@echonotes.ai
            </p>
          </div>
        </div>
        {/* faq section */}
        <div className="mx-auto max-w-5xl divide-y divide-gray-900/10 px-6 py-24 sm:py-1 lg:px-8 lg:py-2">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
            Frequently asked questions
          </h2>
          <dl className="mt-10 space-y-8 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <div
                key={faq.id}
                className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8"
              >
                <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">
                  {faq.question}
                </dt>
                <dd className="mt-4 lg:col-span-7 lg:mt-0">
                  <p className="text-base leading-7 text-gray-600">
                    {faq.answer}
                  </p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
        <Footer />
      </div>
    </>
  );
}
