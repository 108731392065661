import { XCircleIcon } from "@heroicons/react/24/outline";

interface ErrorNotificationProps {
  message: string;
}

const ErrorNotification = ({ message }: ErrorNotificationProps) => {
  return (
    <div className="flex items-center justify-center py-28">
      <div className="bg-red-50 border border-red-200 p-4 w-full max-w-2xl mx-4 rounded-lg">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <p className="text-md text-red-700">{message}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorNotification;
