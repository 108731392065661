import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import SEO from "../components/SEO";

import UseCaseHeader from "../components/UseCaseHeader";
import Footer from "../components/Footer";

export default function ConferencePage() {
  // Google Analytics
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/conference",
      title: "Conference Use-Case Page",
    });
  }, []);

  return (
    <>
      <SEO
        title="Capture your conference insights with Echo"
        description="Use Echo to capture key takeaways from talks, networking conversations, and spontaneous ideas throughout your conference experience."
        canonicalPath="/conference"
      />
      <div className="bg-light-gray">
        <UseCaseHeader />
        {/* Hero and Problem Statement */}
        <div className="mx-auto max-w-7xl lg:px-4 pt-10 sm:pt-20 text-center sm:text-left">
          <div className="flex flex-col sm:flex-row justify-between px-6">
            <div className="pt-12 basis-[60%] pb-10 sm:pb-0">
              <h2 className="text-3xl md:text-5xl md:leading-[1.25em] font-lustria font-bold tracking-tight text-gray-900">
                Make the most of a conference by capturing your thoughts
              </h2>
              <p className="leading-6 sm:leading-7 md:leading-8 tracking-wide font-light mt-4 text-gray-500 sm:text-xl text-l sm:leading-9 md:max-w-xl">
                Use Echo to capture key takeaways from talks, networking
                conversations, and spontaneous ideas throughout your conference
                experience.
              </p>
              <div className="mt-10 flex justify-center sm:justify-start items-center gap-x-6">
                <a
                  href="https://apps.apple.com/us/app/echo-ai-notes/id6503351019"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    ReactGA.event({
                      category: "Download",
                      action: "App Store Click",
                      label: "Conference Use Case Page - Hero Section",
                    })
                  }
                >
                  <img
                    src="/app-store-button-black.png"
                    alt="Download on the App Store"
                    className="w-32 inline mx-auto sm:mx-0"
                  />
                </a>
              </div>
            </div>
            <div className="px-20 pb-20 -mb-40 z-10 basis-[40%]">
              <svg
                role="img"
                viewBox="0 0 366 729"
                className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl"
              >
                <title>App screenshot</title>
                <defs>
                  <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                    <rect rx={36} width={316} height={684} />
                  </clipPath>
                </defs>
                <path
                  d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
                  fill="#4B5563"
                />
                <path
                  d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
                  fill="#343E4E"
                />
                <foreignObject
                  width={316}
                  height={684}
                  clipPath="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
                  transform="translate(24 24)"
                >
                  <img
                    alt="Recording a voice note with Echo"
                    src="iphone-mock-recording.png"
                  />
                </foreignObject>
              </svg>
            </div>
          </div>
        </div>

        {/* App Store Rating */}
        <section className="relative bg-custom-green isolate overflow-hidden px-6 py-24 sm:py-32 lg:px-8">
          <div className="mx-auto max-w-2xl lg:max-w-4xl">
            <img
              src="/logo-app-store-rating-white.png"
              alt="Download on the App Store"
              className="mx-auto max-w-36"
            />
            <figure className="mt-10">
              <blockquote className="text-center text-xl/8 font-lustria tracking-tight md:text-2xl text-white sm:text-2xl/9">
                <p>
                  "I've had great conversations, like one about AI consciousness
                  with a friend. Later, I used Echo to capture my thoughts as I
                  walked, organizing ideas for deeper exploration. Echo helps me
                  connect those moments into something meaningful."
                </p>
              </blockquote>
              <figcaption className="mt-8">
                <div className="mt-4 flex items-center justify-center space-x-3 text-base">
                  <div className="font-semibold text-white opacity-70">
                    Anonymous
                  </div>
                </div>
              </figcaption>
            </figure>
          </div>
        </section>

        {/* Features */}
        <div className="mx-auto max-w-2xl px-6 py-24 sm:py-32 lg:max-w-7xl lg:px-8">
          <div className="mx-auto max-w-3xl text-center pb-0 md:pb-10">
            <h2 className="text-3xl md:text-5xl font-bold md:leading-[1.25em] md:max-w-xl md:mx-auto font-lustria tracking-tight text-gray-900">
              Never miss a valuable insight again
            </h2>
          </div>
          <div className="mt-16 mx-auto max-w-5xl">
            <div className="flex flex-col sm:flex-row justify-between items-center">
              <div className="basis-[40%] px-20 sm:px-0 pb-10 sm:pb-0">
                <svg
                  role="img"
                  viewBox="0 0 366 729"
                  className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl"
                >
                  <title>App screenshot</title>
                  <defs>
                    <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                      <rect rx={36} width={316} height={684} />
                    </clipPath>
                  </defs>
                  <path
                    d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
                    fill="#4B5563"
                  />
                  <path
                    d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
                    fill="#343E4E"
                  />
                  <foreignObject
                    width={316}
                    height={684}
                    clipPath="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
                    transform="translate(24 24)"
                  >
                    <img
                      alt="Asking Echo to recap conference insights"
                      src="iphone-mock-response-conference.png"
                    />
                  </foreignObject>
                </svg>
              </div>
              <div className="basis-[50%]">
                <div className="mb-12 sm:mb-16">
                  <h3 className="text-xl sm:text-xl sm:max-w-xs font-semibold tracking-tight text-gray-900">
                    Capture insights instantly between sessions
                  </h3>
                  <p className="mt-2 sm:text-md text-gray-500">
                    Record quick thoughts about talks, demos, and conversations
                    while they're fresh in your mind.
                  </p>
                </div>
                <div className="mb-12 sm:mb-16">
                  <h3 className="text-xl sm:text-xl sm:max-w-xs font-semibold tracking-tight text-gray-900">
                    Echo organizes your notes by topic and potential
                    collaborations
                  </h3>
                  <p className="mt-2 sm:text-md text-gray-500">
                    Let AI help you connect the dots between different
                    conversations and identify follow-up opportunities.
                  </p>
                </div>
                <div className="mb-12 sm:mb-16">
                  <h3 className="text-xl sm:text-xl sm:max-w-xs font-semibold tracking-tight text-gray-900">
                    Turn conference insights into action items
                  </h3>
                  <p className="mt-2 sm:text-md text-gray-500">
                    Export your organized thoughts to your favorite tools for
                    follow-ups and project planning.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Customer testimonials */}
        <div className="bg-dark-bg py-24 sm:py-32" id="testimonials">
          <div className="mx-auto max-w-7xl px-8 md:px-6 lg:px-8">
            <div className="flex md:flex-row flex-col justify-between">
              <div className="basis-[45%] pb-10 md:pb-0">
                <p className="text-white mb-3 relative font-lustria tracking-tight md:text-2xl">
                  <span className="text-3xl absolute -left-[0.5em]">"</span>
                  After a meeting, I often get up to make a cup of tea and I
                  start replaying the meeting in my head and I realize I forget
                  something. That's when I record a note in Echo.
                </p>
                <p className="font-semibold text-white opacity-70">Anonymous</p>
              </div>
              <div className="basis-[45%]">
                <p className="text-white mb-3 relative font-lustria tracking-tight md:text-2xl">
                  <span className="text-3xl font- absolute -left-[0.5em]">
                    "
                  </span>
                  Sometimes, I capture action steps. Things I need to do
                  following the meeting. But more often it's higher level
                  thinking.
                </p>
                <p className="font-semibold text-white opacity-70">Anonymous</p>
              </div>
            </div>
          </div>
        </div>

        {/* Call to action */}
        <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl font-lustria font-bold sm:leading-[1.5]">
              Get started
              <br />
              Download Echo for free.
            </h2>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="https://apps.apple.com/us/app/echo-ai-notes/id6503351019"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  ReactGA.event({
                    category: "Download",
                    action: "App Store Click",
                    label: "Conference Use Case Page - CTA Section",
                  })
                }
              >
                <img
                  src="/app-store-button-black.png"
                  alt="Download on the App Store"
                  className="w-32 inline mx-auto sm:mx-0"
                />
              </a>
              <a
                href="/about"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                About us <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}
