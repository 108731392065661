import React, { useEffect, useState, useCallback } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import ReactMarkdown from "react-markdown";
import LoadingSpinner from "../components/LoadingSpinner";
import {
  ClipboardDocumentIcon,
  Bars3BottomLeftIcon,
  SpeakerWaveIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import ErrorNotification from "../components/ErrorNotification";
import { formatDate } from "../utils";
import { useToast } from "../components/Toast";
import { formatISO } from "date-fns";
import SEO from "../components/SEO";

interface Note {
  id: number;
  title: string;
  createdAt: string;
  format: string;
  // TODO: Add author response from backend
  author: string;
}

interface Topic {
  id: number;
  name: string;
  summary: string;
  color: string;
}

const TopicDetails = () => {
  const { topicId } = useParams<{ topicId: string }>();
  const { getAccessTokenSilently } = useAuth0();
  const { showToast } = useToast();
  const [topic, setTopic] = useState<Topic | null>(null);
  const [notes, setNotes] = useState<Note[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [copied, setCopied] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTopicDetails = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/v2/topics/${topicId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch topic details");
        }

        const data = await response.json();
        setTopic(data.topic);
        setNotes(data.notes);
      } catch (error) {
        console.error("Error fetching topic details:", error);
        setError("Failed to fetch topic details.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchTopicDetails();
  }, [topicId, getAccessTokenSilently]);

  const handleCopy = useCallback(async () => {
    if (!topic?.summary) return;
    try {
      window.analytics?.track("Topic Details | Copied Topic Summary", {
        summaryLength: topic.summary.length,
      });
      await navigator.clipboard.writeText(topic.summary);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy text:", err);
      showToast("Failed to copy text", "red");
    }
  }, [topic]);

  useEffect(() => {
    const handleKeyDownCopy = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "c") {
        if (
          !window.getSelection()?.toString() &&
          !isEditing &&
          notes.length > 1
        ) {
          event.preventDefault();
          handleCopy();
        }
      }
    };

    document.addEventListener("keydown", handleKeyDownCopy);
    return () => document.removeEventListener("keydown", handleKeyDownCopy);
  }, [handleCopy, isEditing, notes.length]);

  useEffect(() => {
    const handleKeyDownEdit = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "e" && !isEditing) {
        event.preventDefault();
        window.analytics?.track("Topic Details | Entered Edit Mode", {
          topicId: topicId,
          method: "KeyboardShortcut",
        });
        setEditedName(topic?.name || "");
        setIsEditing(true);
      } else if (event.key === "Escape" && isEditing) {
        event.preventDefault();
        handleCancelEdit();
      }
    };

    document.addEventListener("keydown", handleKeyDownEdit);
    return () => document.removeEventListener("keydown", handleKeyDownEdit);
  }, [isEditing, topic, topicId]);

  const handleSave = async () => {
    if (!topicId) return;
    if (editedName.trim() === topic?.name) {
      setIsEditing(false);
      return;
    }

    setIsSaving(true);
    const currentTimestamp = formatISO(new Date());

    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v2/topics/${topicId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            name: editedName.trim(),
            currentTimestamp,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        if (
          response.status === 400 &&
          errorData.errorType === "duplicate_name_error"
        ) {
          showToast(
            "A topic with this name already exists. Please change the name and try again.",
            "red"
          );
          return;
        }

        throw new Error(
          `Failed to save topic: ${response.status}${
            errorData?.message ? ` - ${errorData.message}` : ""
          }`
        );
      }

      setTopic((prevTopic) =>
        prevTopic
          ? {
              ...prevTopic,
              name: editedName.trim(),
            }
          : null
      );

      setIsEditing(false);
      showToast("Topic saved successfully", "green");
      window.analytics?.track("Topic Details | Saved Edited Topic", {
        topicId: topicId,
      });
    } catch (error) {
      console.error("Error saving topic:", error);
      showToast(
        error instanceof Error ? error.message : "Failed to save topic",
        "red"
      );
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancelEdit = () => {
    setEditedName(topic?.name || "");
    setIsEditing(false);
    window.analytics?.track("Topic Details | Canceled Edit Mode", {
      topicId: topicId,
    });
  };

  const handleDelete = async () => {
    if (!topicId) return;
    setIsSaving(true);

    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v2/topics/${topicId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete topic");
      }

      window.analytics?.track("Topic Details | Deleted Topic", {
        topicId: topicId,
      });

      navigate("/dashboard");
      showToast("Topic deleted successfully", "green");
    } catch (error) {
      console.error("Error deleting topic:", error);
      showToast(
        error instanceof Error ? error.message : "Failed to delete topic",
        "red"
      );
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape" && showDeleteConfirmation) {
        setShowDeleteConfirmation(false);
      }
    };

    document.addEventListener("keydown", handleEscape);
    return () => document.removeEventListener("keydown", handleEscape);
  }, [showDeleteConfirmation]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <ErrorNotification message={error} />;
  }

  if (!topic) {
    return <ErrorNotification message="No topic found." />;
  }

  return (
    <>
      <SEO
        title={`Topic Details | Echo`}
        description="View and manage your topic notes"
        isAuthRequired={true}
        canonicalPath={`/topics/${topicId}`}
      />
      <div>
        <div
          className="w-full border-t-[7px]"
          style={{ borderColor: topic.color }}
        />
        <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:px-8 py-6 lg:py-12">
          <div className="mb-8">
            <div className="flex items-center justify-between mb-4">
              {isEditing ? (
                <div className="w-full space-y-2">
                  <input
                    type="text"
                    value={editedName}
                    onChange={(e) => setEditedName(e.target.value)}
                    maxLength={60}
                    className="w-full text-3xl font-bold text-gray-900 rounded-md border border-gray-300 px-4 py-2 focus:border-indigo-500 focus:outline-none"
                    disabled={isSaving}
                  />
                  <div className="text-xs text-gray-500">
                    {editedName.length}/60 characters
                  </div>
                  <div className="flex justify-end gap-2">
                    <button
                      onClick={handleCancelEdit}
                      className="rounded bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      disabled={isSaving}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleSave}
                      disabled={isSaving || !editedName.trim()}
                      className="rounded bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50"
                    >
                      {isSaving ? "Saving..." : "Save"}
                    </button>
                  </div>
                </div>
              ) : (
                <h1 className="text-3xl font-bold text-gray-900">
                  {topic.name}
                </h1>
              )}
            </div>
            <div className="flex items-center gap-2 mb-4">
              {!isEditing && (
                <>
                  <button
                    onClick={handleCopy}
                    disabled={isEditing || notes.length <= 1}
                    className={`inline-flex items-center gap-1 rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50 ${
                      isEditing ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                  >
                    <ClipboardDocumentIcon className="h-4 w-4" />
                    <span>{copied ? "Copied!" : "Copy"}</span>
                    {/* TO DO: Address deprecated feaure */}
                    <span className="text-gray-400 ml-1">
                      {navigator.platform.toLowerCase().includes("mac")
                        ? "⌘"
                        : "ctrl"}{" "}
                      + C
                    </span>
                  </button>
                  <button
                    onClick={() => {
                      window.analytics?.track(
                        "Topic Details | Entered Edit Mode",
                        {
                          topicId: topicId,
                          method: "button_press",
                        }
                      );
                      setEditedName(topic.name);
                      setIsEditing(true);
                    }}
                    className="inline-flex items-center gap-1 rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    <PencilIcon className="h-4 w-4" />
                    <span>Edit</span>
                    <span className="text-gray-400 ml-1">
                      {navigator.platform.toLowerCase().includes("mac")
                        ? "⌘"
                        : "ctrl"}{" "}
                      + E
                    </span>
                  </button>
                  <button
                    onClick={() => setShowDeleteConfirmation(true)}
                    disabled={isSaving}
                    className="inline-flex items-center gap-1 rounded bg-white px-2 py-1 text-xs font-semibold text-red-600 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-50 disabled:opacity-50"
                  >
                    <TrashIcon className="h-4 w-4" />
                    <span>{isSaving ? "Deleting..." : "Delete"}</span>
                  </button>
                </>
              )}
            </div>
            {notes.length <= 1 ? (
              <div className="text-center bg-gray-200 px-6 py-8 rounded-lg my-10">
                <Bars3BottomLeftIcon className="mx-auto h-10 w-10 text-gray-600" />
                <h3 className="mt-2 text-md font-semibold text-gray-900">
                  No topic summary yet
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  When you have more than 1 note about this topic, you'll see a
                  summary here.
                </p>
              </div>
            ) : (
              <div className="text-gray-600 mb-6 prose">
                <ReactMarkdown>{topic.summary}</ReactMarkdown>
              </div>
            )}
          </div>

          <div>
            <h3 className="text-md font-semibold text-gray-900 mb-4">
              Related Notes
            </h3>
          </div>
          <div className="bg-white shadow rounded-lg">
            <ul className="divide-y divide-gray-200">
              {notes.map((note) => (
                <li key={note.id}>
                  <Link
                    to={`/notes/${note.id}`}
                    className="block hover:bg-gray-50"
                  >
                    <div className="px-4 py-4 sm:px-6">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-2">
                          {note.format === "audio" && (
                            <SpeakerWaveIcon className="h-5 w-5 text-gray-400" />
                          )}
                          {note.author === "system" && (
                            <img
                              className="h-5 w-auto opacity-50"
                              src="/logo192Black.png"
                              alt="System Logo"
                            />
                          )}
                          <p className="text-sm font-medium text-gray-900 truncate">
                            {note.title}
                          </p>
                        </div>
                        <div className="ml-2 flex-shrink-0 flex">
                          <p className="text-sm text-gray-500">
                            {formatDate(note.createdAt)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {showDeleteConfirmation && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-50">
            <div className="fixed inset-0 z-50 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <TrashIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <h3 className="text-base font-semibold leading-6 text-gray-900">
                        Delete Topic
                      </h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete this topic?
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={handleDelete}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setShowDeleteConfirmation(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TopicDetails;
