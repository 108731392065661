import React from "react";
import SpinningCircle from "./SpinningCircle";

const LoadingSpinner: React.FC = () => {
  return (
    <div className="grid min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg lg:overflow-visible">
      <SpinningCircle size="lg" />
    </div>
  );
};

export default LoadingSpinner;
