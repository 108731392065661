import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

interface SignupButtonProps {
  className?: string;
}

const SignupButton = ({ className = "" }: SignupButtonProps) => {
  const { loginWithRedirect } = useAuth0();

  const handleSignup = () => {
    window.analytics?.track("Signup | Started", {
      location: window.location.pathname,
    });

    loginWithRedirect({
      authorizationParams: {
        screen_hint: "signup",
      },
    });
  };

  return (
    <button
      onClick={handleSignup}
      className={`rounded-md bg-gray-900 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900 ${className}`}
    >
      Sign Up
    </button>
  );
};

export default SignupButton;
