import React, { useEffect, useState, useCallback, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { formatISO } from "date-fns";
import { SparklesIcon, PlayIcon, StopIcon } from "@heroicons/react/24/solid";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import LoadingSpinner from "../components/LoadingSpinner";
import ErrorNotification from "../components/ErrorNotification";
import { useToast } from "../components/Toast";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import { formatDate } from "../utils";
import { TrashIcon } from "@heroicons/react/24/outline";
import { PencilIcon } from "@heroicons/react/24/outline";
import SEO from "../components/SEO";

interface Topic {
  id: number;
  name: string;
  color: string;
  numNotes: number;
}

interface Note {
  id: number;
  title: string;
  text: string;
  echoText: string;
  createdAt: string;
  updatedAt: string;
  author: string;
  format: string;
  isEchoTextEnabled: boolean;
}

interface GetNoteResponse {
  note: Note;
  topics: Topic[];
}

interface AudioResponse {
  audioRecording: string;
}

const NoteDetails = () => {
  const { noteId } = useParams<{ noteId: string }>();
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [note, setNote] = useState<Note | null>(null);
  const [topics, setTopics] = useState<Topic[]>([]);
  const [isFetchingNote, setIsFetchingNote] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [copied, setCopied] = useState(false);
  const [isUpdatingEchoVersion, setIsUpdatingEchoVersion] = useState(false);
  const [isPlayingAudio, setIsPlayingAudio] = useState(false);
  const [isFetchingAudio, setIsFetchingAudio] = useState(false);
  const [playbackProgress, setPlaybackProgress] = useState(0);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const progressIntervalRef = useRef<number | null>(null);
  const { showToast } = useToast();
  const navigate = useNavigate();
  const [isArchiving, setIsArchiving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState("");
  const [editedTitle, setEditedTitle] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const playAudio = (audio: HTMLAudioElement) => {
    audio
      .play()
      .then(() => {
        setIsPlayingAudio(true);
        // Start progress tracking
        progressIntervalRef.current = window.setInterval(() => {
          if (audio.duration) {
            setPlaybackProgress(audio.currentTime / audio.duration);
          }
        }, 100);
      })
      .catch((error) => {
        console.error("Error playing audio:", error);
        showToast("Failed to play audio recording.", "red");
        setIsPlayingAudio(false);
      });
  };

  const fetchAudio = async () => {
    if (!noteId) return;

    setIsFetchingAudio(true);
    try {
      const token = await getAccessTokenSilently();
      const url = `${process.env.REACT_APP_API_BASE_URL}/api/v2/notes/${noteId}/audio`;

      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `Failed to fetch audio: ${response.status}${
            errorText ? ` - ${errorText}` : ""
          }`
        );
      }

      const data: AudioResponse = await response.json();
      if (!data.audioRecording) {
        throw new Error("No audio recording found in response");
      }

      // Create an audio element and set the source
      const audio = new Audio(`data:audio/mp3;base64,${data.audioRecording}`);
      audioRef.current = audio;

      // Set up event listeners
      audio.addEventListener("ended", () => {
        setIsPlayingAudio(false);
        setPlaybackProgress(0);
        if (progressIntervalRef.current) {
          window.clearInterval(progressIntervalRef.current);
        }
      });

      // Wait for audio to be loaded before playing
      audio.addEventListener(
        "canplaythrough",
        () => {
          playAudio(audio);
        },
        { once: true } // ensures the listener only gets invoked once, and is then cleaned up
      );
    } catch (error) {
      console.error("Error fetching audio:", error);
      showToast(
        error instanceof Error
          ? error.message
          : "Failed to play audio recording.",
        "red"
      );
      setIsPlayingAudio(false);
    } finally {
      setIsFetchingAudio(false);
    }
  };

  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    setIsPlayingAudio(false);
    setPlaybackProgress(0);
    if (progressIntervalRef.current) {
      window.clearInterval(progressIntervalRef.current);
    }
  };

  const handlePlayPause = () => {
    if (isPlayingAudio) {
      stopAudio();
    } else if (audioRef.current) {
      const audio = audioRef.current;
      playAudio(audio);
    } else {
      fetchAudio();
    }
  };

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.src = "";
        audioRef.current.load();
        audioRef.current.remove();
        audioRef.current = null;
      }
      stopAudio();
      if (progressIntervalRef.current) {
        window.clearInterval(progressIntervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const fetchNoteDetails = async () => {
      setErrorMessage("");
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/v2/notes/${noteId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch note details");
        }

        const data = await response.json();
        setNote(data.note);
        setTopics(data.topics);
        setEditedText(data.note.text);
        setEditedTitle(data.note.title);
      } catch (error) {
        setErrorMessage("Failed to fetch note details.");
      } finally {
        setIsFetchingNote(false);
      }
    };

    if (isAuthenticated && noteId) {
      fetchNoteDetails();
    }
  }, [noteId, getAccessTokenSilently, isAuthenticated]);

  useEffect(() => {
    const handleKeyDownEdit = (event: KeyboardEvent) => {
      if (
        (event.metaKey || event.ctrlKey) &&
        event.key === "e" &&
        !isEditing &&
        !note?.isEchoTextEnabled
      ) {
        event.preventDefault();
        window.analytics?.track("Note Details | Entered Edit Mode", {
          noteId: noteId,
          method: "KeyboardShortcut",
        });
        setIsEditing(true);
      } else if (event.key === "Escape" && isEditing) {
        event.preventDefault();
        handleCancelEdit();
      }
    };

    document.addEventListener("keydown", handleKeyDownEdit);
    return () => document.removeEventListener("keydown", handleKeyDownEdit);
  }, [isEditing, note, noteId]);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape" && showDeleteConfirmation) {
        setShowDeleteConfirmation(false);
      }
    };

    document.addEventListener("keydown", handleEscape);
    return () => document.removeEventListener("keydown", handleEscape);
  }, [showDeleteConfirmation]);

  const handleCopy = useCallback(async () => {
    if (!note) return;
    const textToCopy = note.isEchoTextEnabled ? note.echoText : note.text;
    try {
      window.analytics?.track("Note Details | Copied Note", {
        noteLength: note.text.length,
      });

      await navigator.clipboard.writeText(textToCopy);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy text:", err);
    }
  }, [note]);

  useEffect(() => {
    const handleKeyDownCopy = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "c") {
        if (!window.getSelection()?.toString() && !isEditing) {
          event.preventDefault();
          handleCopy();
        }
      }
    };

    document.addEventListener("keydown", handleKeyDownCopy);
    return () => document.removeEventListener("keydown", handleKeyDownCopy);
  }, [handleCopy, isEditing]);

  const handleSave = async () => {
    if (!noteId) return;
    setIsSaving(true);

    const currentTimestamp = formatISO(new Date());

    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v2/notes/${noteId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            text: editedText.trim(),
            title: editedTitle !== note?.title ? editedTitle.trim() : undefined,
            currentTimestamp,
          }),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `Failed to save note: ${response.status}${
            errorText ? ` - ${errorText}` : ""
          }`
        );
      }

      const data = await response.json();
      setNote(data.note);
      setIsEditing(false);
      showToast("Note saved successfully", "green");
      window.analytics?.track("Note Details | Saved Edited Note", {
        noteId: noteId,
      });
    } catch (error) {
      console.error("Error saving note:", error);
      showToast(
        error instanceof Error ? error.message : "Failed to save note",
        "red"
      );
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancelEdit = () => {
    setEditedText(note?.text || "");
    setEditedTitle(note?.title || "");
    setIsEditing(false);
    window.analytics?.track("Note Details | Canceled Edit Mode", {
      noteId: noteId,
    });
  };

  const handleArchive = async () => {
    if (!noteId || isArchiving) return;

    window.analytics?.track("Note Details | Deleted Note", {
      noteId: noteId,
    });

    setIsArchiving(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v2/notes/${noteId}/archive`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            currentTimestamp: formatISO(new Date()),
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to archive note");
      }

      navigate("/notes");
      showToast("Note archived successfully", "green");
    } catch (error) {
      console.error("Error archiving note:", error);
      showToast("Failed to archive note", "red");
    } finally {
      setIsArchiving(false);
    }
  };

  if (isLoading || isFetchingNote) {
    return (
      <div className="flex flex-col items-center gap-4 py-28">
        <LoadingSpinner />
      </div>
    );
  }

  if (errorMessage) {
    return <ErrorNotification message={errorMessage} />;
  }

  if (!note) {
    return <ErrorNotification message="Note not found." />;
  }

  return (
    <>
      <SEO
        title={`Note Details | Echo`}
        description="View and edit your note"
        isAuthRequired={true}
        canonicalPath={`/notes/${noteId}`}
      />
      <div className="py-28">
        <div className="mx-auto max-w-4xl px-4 sm:px-6 lg:px-8">
          <div className="flex items-start gap-4">
            {note.format === "audio" && (
              <div className="relative">
                {isFetchingAudio ? (
                  <div className="w-8 h-8 flex items-center justify-center">
                    <LoadingSpinner />
                  </div>
                ) : (
                  <button
                    onClick={handlePlayPause}
                    className="relative w-8 h-8 flex items-center justify-center"
                  >
                    <div className="absolute inset-0">
                      <svg className="w-full h-full" viewBox="0 0 32 32">
                        <circle
                          className="stroke-current text-gray-300"
                          fill="none"
                          strokeWidth="2"
                          cx="16"
                          cy="16"
                          r="15"
                        />
                        <circle
                          className="stroke-current text-indigo-600"
                          fill="none"
                          strokeWidth="2"
                          cx="16"
                          cy="16"
                          r="15"
                          strokeDasharray={94.2477796076938}
                          strokeDashoffset={
                            94.2477796076938 * (1 - playbackProgress)
                          }
                          transform="rotate(-90 16 16)"
                        />
                      </svg>
                    </div>
                    {isPlayingAudio ? (
                      <StopIcon className="w-4 h-4 text-indigo-600" />
                    ) : (
                      <PlayIcon className="w-4 h-4 text-indigo-600" />
                    )}
                  </button>
                )}
              </div>
            )}
            {note.author === "system" && (
              <img
                className="h-8 w-auto mt-1 opacity-50"
                src="/logo192Black.png"
                alt="Logo"
              />
            )}
            <div className="flex-1">
              {isEditing ? (
                <div className="space-y-2 mb-4">
                  <input
                    type="text"
                    value={editedTitle}
                    onChange={(e) => setEditedTitle(e.target.value)}
                    maxLength={60}
                    className="w-full text-2xl font-bold text-gray-900 rounded-md border border-gray-300 px-4 py-2 focus:border-indigo-500 focus:outline-none"
                    disabled={isSaving}
                  />
                  <div className="text-xs text-gray-500">
                    {editedTitle.length}/60 characters
                  </div>
                </div>
              ) : (
                <h1 className="text-2xl font-bold text-gray-900 mb-2">
                  {note.title}
                </h1>
              )}
              <span className="text-sm text-gray-500">
                {formatDate(note.updatedAt)}
              </span>
            </div>
          </div>

          {topics && topics.length > 0 && (
            <div className="flex flex-wrap gap-2 my-4">
              {topics.map((topic) => (
                <Link
                  key={topic.id}
                  to={`/topics/${topic.id}`}
                  className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium hover:bg-gray-100"
                  style={{
                    backgroundColor:
                      topic.numNotes > 1 ? "white" : "bg-gray-100",
                    color: topic.numNotes > 1 ? topic.color : "gray",
                    borderWidth: 1,
                    borderColor:
                      topic.numNotes > 1 ? topic.color : "bg-gray-100",
                  }}
                >
                  <span
                    className={
                      topic.numNotes <= 1 ? "text-gray-500" : "text-gray-900"
                    }
                  >
                    {topic.name}
                  </span>
                  <span className="ml-2 text-xs text-gray-500">
                    {topic.numNotes} {topic.numNotes === 1 ? "note" : "notes"}
                  </span>
                </Link>
              ))}
            </div>
          )}

          <div className="flex items-center justify-between gap-2 my-6 py-4 border-t border-b border-gray-200">
            <div className="flex items-center gap-4">
              <div
                className={`flex items-center ${isEditing ? "opacity-50" : ""}`}
              >
                <SparklesIcon className="h-5 w-5 text-gray-500 mr-2" />
                <span className="text-sm font-medium text-gray-500 mr-3">
                  Echo's version
                </span>
              </div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={
                    isUpdatingEchoVersion
                      ? !note.isEchoTextEnabled
                      : note.isEchoTextEnabled
                  }
                  onChange={async (e) => {
                    if (isEditing) return;

                    const newValue = e.target.checked;
                    setIsUpdatingEchoVersion(true);
                    try {
                      const token = await getAccessTokenSilently();
                      const response = await fetch(
                        `${process.env.REACT_APP_API_BASE_URL}/api/v2/notes/${noteId}/update-echo-text-status`,
                        {
                          method: "PATCH",
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                          },
                          body: JSON.stringify({ isEchoTextEnabled: newValue }),
                        }
                      );
                      if (!response.ok) {
                        throw new Error("Failed to update Echo text status");
                      }
                      const data = await response.json();
                      setNote(data.note);

                      window.analytics?.track(
                        "Note Details | Toggled Echo's Version",
                        {
                          noteId: noteId,
                          newValue: newValue,
                        }
                      );
                    } catch (error) {
                      console.error(
                        "Failed to update Echo text status:",
                        error
                      );
                      showToast("Failed to update Echo text status", "red");
                    } finally {
                      setIsUpdatingEchoVersion(false);
                    }
                  }}
                />
                <div
                  className={`w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-indigo-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-indigo-600 ${
                    isEditing ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                ></div>
              </label>
            </div>

            <div className="flex items-center gap-2">
              <button
                onClick={handleCopy}
                disabled={isEditing}
                className={`inline-flex items-center gap-1 rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 ${
                  isEditing ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                <ClipboardDocumentIcon className="h-4 w-4" />
                <span>{copied ? "Copied!" : "Copy"}</span>
                <span className="text-gray-400 ml-1">
                  {navigator.platform.toLowerCase().includes("mac")
                    ? "⌘"
                    : "ctrl"}{" "}
                  + C
                </span>
              </button>

              {!isEditing && (
                <button
                  onClick={() => {
                    window.analytics?.track(
                      "Note Details | Entered Edit Mode",
                      {
                        noteId: noteId,
                        method: "button_press",
                      }
                    );
                    setIsEditing(true);
                  }}
                  disabled={note?.isEchoTextEnabled}
                  className="inline-flex items-center gap-1 rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50"
                >
                  <PencilIcon className="h-4 w-4" />
                  <span>Edit</span>
                  <span className="text-gray-400 ml-1">
                    {navigator.platform.toLowerCase().includes("mac")
                      ? "⌘"
                      : "ctrl"}{" "}
                    + E
                  </span>
                </button>
              )}

              <button
                onClick={() => setShowDeleteConfirmation(true)}
                disabled={isArchiving || isEditing}
                className={`inline-flex items-center gap-1 rounded bg-white px-2 py-1 text-xs font-semibold text-red-600 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-50 disabled:opacity-50 ${
                  isEditing ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                <TrashIcon className="h-4 w-4" />
                <span>{isArchiving ? "Deleting..." : "Delete"}</span>
              </button>
            </div>
          </div>
          <div className="prose max-w-none">
            {isEditing ? (
              <div className="mt-6 space-y-4">
                <textarea
                  value={editedText}
                  onChange={(e) => setEditedText(e.target.value)}
                  className="w-full rounded-md border border-gray-300 px-4 py-2 focus:border-indigo-500 focus:outline-none"
                  rows={10}
                  disabled={isSaving}
                />
                <div className="flex justify-end gap-2">
                  <button
                    onClick={handleCancelEdit}
                    className="rounded bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    disabled={isSaving}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleSave}
                    disabled={isSaving || !editedText.trim()}
                    className="rounded bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50"
                  >
                    {isSaving ? "Saving..." : "Save"}
                  </button>
                </div>
              </div>
            ) : (
              <ReactMarkdown>
                {note.isEchoTextEnabled ? note.echoText : note.text}
              </ReactMarkdown>
            )}
          </div>
        </div>
        {showDeleteConfirmation && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-50">
            <div className="fixed inset-0 z-50 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <TrashIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <h3 className="text-base font-semibold leading-6 text-gray-900">
                        Delete Note
                      </h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete this note?
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={handleArchive}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setShowDeleteConfirmation(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default NoteDetails;
