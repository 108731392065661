import React, { createContext, useContext, useState } from "react";
import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";

interface Toast {
  message: string;
  color: "green" | "red";
}

interface ToastContextType {
  showToast: (message: string, color: Toast["color"]) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [toast, setToast] = useState<Toast | null>(null);
  const [show, setShow] = useState(false);

  const showToast = (message: string, color: Toast["color"]) => {
    setToast({ message, color });
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 3000);
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          <Transition
            show={show}
            as={React.Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={`pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 ${
                toast?.color === "green" ? "bg-green-50" : "bg-red-50"
              }`}
            >
              <div className="p-4">
                <div className="flex items-center">
                  <div className="flex-1 w-0">
                    <p
                      className={`text-sm font-medium ${
                        toast?.color === "green"
                          ? "text-green-900"
                          : "text-red-900"
                      }`}
                    >
                      {toast?.message}
                    </p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className={`inline-flex rounded-md ${
                        toast?.color === "green"
                          ? "text-green-400 hover:text-green-500 focus:ring-green-500"
                          : "text-red-400 hover:text-red-500 focus:ring-red-500"
                      } focus:outline-none focus:ring-2 focus:ring-offset-2`}
                      onClick={() => setShow(false)}
                    >
                      <span className="sr-only">Dismiss</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </ToastContext.Provider>
  );
};
