import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

interface LoginButtonProps {
  className?: string;
}

const LoginButton = ({ className = "" }: LoginButtonProps) => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = () => {
    window.analytics?.track("Login | Started", {
      location: window.location.pathname,
    });

    loginWithRedirect();
  };

  return (
    <button
      onClick={handleLogin}
      className={`rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 ${className}`}
    >
      Log In
    </button>
  );
};

export default LoginButton;
