import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import LoadingSpinner from "../components/LoadingSpinner";
import NotLoggedInAlert from "../components/NotLoggedInAlert";
import SEO from "../components/SEO";
import ReactGA from "react-ga4";

interface Topic {
  id: number;
  name: string;
  color: string;
  updatedAt: string;
  numNotes: number;
}

const Dashboard = () => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();
  const [accessToken, setAccessToken] = useState<string | null>("");
  const [topics, setTopics] = useState<Topic[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isTopicsLoading, setIsTopicsLoading] = useState(true);

  // get and store access token
  useEffect(() => {
    const fetchAndSetAccessToken = async () => {
      const domain = process.env.REACT_APP_API_BASE_URL;
      try {
        const fetchedAccessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: domain,
          },
        });
        setAccessToken(fetchedAccessToken);
      } catch (e) {
        setIsError(true);
        setErrorMessage(
          "Failed to get authenticated access token. Try refreshing your page."
        );
      }
    };
    if (!isLoading) {
      fetchAndSetAccessToken();
    }
  }, [getAccessTokenSilently, isLoading]);

  // Fetch topics
  // TO DO: Add pagination
  useEffect(() => {
    const fetchTopics = async () => {
      if (!accessToken) return;

      try {
        setIsTopicsLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/v2/topics`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch topics");
        }

        const data = await response.json();
        setTopics(data.topics);
      } catch (error) {
        setIsError(true);
        setErrorMessage("Failed to fetch topics.");
      } finally {
        setIsTopicsLoading(false);
      }
    };

    fetchTopics();
  }, [accessToken]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!isAuthenticated) {
    console.log("Dashboard: User is not authenticated.");
    return <NotLoggedInAlert />;
  }

  if (!user) {
    console.error("Dashboard: User is not defined. Something went wrong.");
    return <div>The user is not defined.</div>;
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const dateOptions: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "numeric",
      year: "numeric",
    };
    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };
    const dateStr = new Intl.DateTimeFormat("en-US", dateOptions).format(date);
    const timeStr = new Intl.DateTimeFormat("en-US", timeOptions).format(date);
    return `${dateStr} at ${timeStr}`;
  };

  return (
    <>
      <SEO
        title="Dashboard | Echo"
        description="View your notes and topics at a glance"
        isAuthRequired={true}
      />
      <div className="py-20">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          {isError && <div className="text-red-500">{errorMessage}</div>}
          {isTopicsLoading ? (
            <LoadingSpinner />
          ) : topics.length === 0 ? (
            <div className="text-center max-w-2xl mx-auto my-10">
              <img
                src="/logo-app-store-rating.png"
                alt="App Store Rating"
                className="sm:w-auto max-w-32 inline mb-8"
              />
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl font-lustria font-bold sm:leading-[1.5] mb-2">
                Start capturing thoughts
              </h2>
              <p className="text-lg text-gray-600 mb-8">
                Echo mobile lets you quickly capture thoughts on the go, while
                the web app helps you explore and develop them further. Download
                the mobile app to begin.
              </p>
              <a
                href="https://apps.apple.com/us/app/echo-ai-notes/id6503351019"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  ReactGA.event({
                    category: "Download",
                    action: "App Store Click",
                    label: "Dashboard Zero State",
                  })
                }
              >
                <img
                  src="/app-store-button-black.png"
                  alt="Download on the App Store"
                  className="w-32 inline mx-auto"
                />
              </a>
            </div>
          ) : (
            <>
              <h1 className="text-2xl font-bold text-gray-900 mb-6">Topics</h1>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                {topics.map((topic: Topic) => (
                  <Link to={`/topics/${topic.id}`} key={topic.id}>
                    <div
                      className="overflow-hidden rounded-lg bg-white shadow"
                      style={{
                        borderLeft: `7px solid ${topic.color}`,
                        backgroundColor:
                          topic.numNotes <= 1 ? "#f0f0f0" : "bg-gray-100",
                      }}
                    >
                      <div className="px-4 py-5 sm:p-6">
                        <h2
                          className={`text-lg font-medium pb-1 ${
                            topic.numNotes <= 1
                              ? "text-gray-500"
                              : "text-gray-900"
                          }`}
                        >
                          {topic.name}
                        </h2>
                        <div className="flex flex-row justify-between">
                          <p className="mt-1 text-sm text-gray-500">
                            {formatDate(topic.updatedAt)}
                          </p>
                          <p className="mt-1 text-sm text-gray-500">
                            {topic.numNotes}{" "}
                            {topic.numNotes === 1 ? "note" : "notes"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
