import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import SEO from "../components/SEO";
import PublicHeader from "../components/PublicHeader";
import Footer from "../components/Footer";

const features = [
  {
    name: "Quick Voice Capture",
    description:
      "Start recording your thoughts with a single tap. Just speak naturally about your experiences, memories, or ideas.",
    number: "1",
  },
  {
    name: "AI-Powered Organization",
    description:
      "Echo automatically cleans up your voice notes and intelligently organizes them by topics.",
    number: "2",
  },
  {
    name: "From Notes to Memoir",
    description:
      "When you're ready, ask Echo to transform your collected thoughts into a first draft of your memoir.",
    number: "3",
  },
];

export default function ChroniclePage() {
  // Google Analytics
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/chronicle",
      title: "Chronicle Use-Case Page",
    });
  }, []);

  return (
    <>
      <SEO
        title="Write your life story with voice notes - Chronicle"
        description="Capture your thoughts in meaningful moments. Build a memory of your life that you can share with your family and friends."
        canonicalPath="/chronicle"
      />
      <div className="bg-light-gray">
        <PublicHeader />

        {/* Hero */}
        <div className="mx-auto max-w-2xl px-6 py-28 sm:py-60 lg:py-32">
          <svg
            aria-hidden="true"
            className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
          >
            <defs>
              <pattern
                x="50%"
                y={-1}
                id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                width={200}
                height={200}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
              width="100%"
              height="100%"
              strokeWidth={0}
            />
          </svg>
          <div className="text-center">
            <img
              src="illustration-capture-random.png"
              alt="Illustration Capture"
              className="mx-auto mb-4 w-5/12 h-auto"
            />
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl font-lustria font-bold sm:leading-[1.2]s">
              Write your life story with voice notes
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Capture your thoughts in meaningful moments. Build a memory of
              your life that you can share with your family and friends.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="https://apps.apple.com/us/app/echo-ai-notes/id6503351019"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  ReactGA.event({
                    category: "Download",
                    action: "App Store Click",
                    label: "Writing Use Case Page - Hero Section",
                  })
                }
              >
                <img
                  src="/app-store-button-black.png"
                  alt="Download on the App Store"
                  className="w-32 mx-auto sm:mx-0"
                />
              </a>
              <a
                href="/"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Learn more <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>

        {/* App Store Rating */}
        <section className="relative bg-custom-green isolate overflow-hidden px-6 py-24 sm:py-32 lg:px-8">
          <div className="mx-auto max-w-2xl lg:max-w-4xl">
            <img
              src="/logo-app-store-rating-white.png"
              alt="Download on the App Store"
              className="mx-auto max-w-36"
            />
            <figure className="mt-10">
              <blockquote className="text-center text-xl/8 font-lustria tracking-tight md:text-2xl text-white sm:text-2xl/9">
                <p>
                  “I'm toying with the idea of writing a book. I'm not quite
                  brave enough yet to say I want to write one, but I do. Echo
                  makes it easy to get started.”
                </p>
              </blockquote>
              <figcaption className="mt-8">
                <div className="mt-4 flex items-center justify-center space-x-3 text-base">
                  <div className="font-semibold text-white opacity-70">
                    Anonymous
                  </div>
                </div>
              </figcaption>
            </figure>
          </div>
        </section>

        {/* Features Section */}
        <div className="overflow-hidden py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div className="lg:pr-8 lg:pt-4">
                <div className="lg:max-w-lg">
                  <h2 className="text-base font-semibold text-indigo-600">
                    How It Works
                  </h2>
                  <p className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl font-lustria font-bold sm:leading-[1.2]s">
                    Three steps to your story
                  </p>
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                    Echo transforms your casual voice notes into a beautifully
                    organized memoir, making the journey of documenting your
                    life effortless.
                  </p>
                  <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                    {features.map((feature) => (
                      <div key={feature.name} className="relative pl-9">
                        <dt className="inline font-semibold text-gray-900">
                          <span className="absolute left-1 top-1 flex h-5 w-5 items-center justify-center rounded-full bg-indigo-50 text-sm font-medium text-indigo-600">
                            {feature.number}
                          </span>
                          {feature.name}
                        </dt>{" "}
                        <dd className="inline">{feature.description}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
              <img
                src="/showcase-memoir.png"
                alt="Chronicle app interface"
                width={2432}
                height={1442}
                className="w-[48rem] max-w-none rounded-xl sm:w-[57rem] md:-ml-4 lg:-ml-0"
              />
            </div>
          </div>
        </div>

        {/* Call to action */}
        <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl font-lustria font-bold sm:leading-[1.5]">
              Get started
              <br />
              Download Echo for free.
            </h2>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="https://apps.apple.com/us/app/echo-ai-notes/id6503351019"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  ReactGA.event({
                    category: "Download",
                    action: "App Store Click",
                    label: "Writing Use Case Page - Hero Section",
                  })
                }
              >
                <img
                  src="/app-store-button-black.png"
                  alt="Download on the App Store"
                  className="w-32 inline mx-auto sm:mx-0"
                />
              </a>
              <a
                href="/about"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                About us <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}
